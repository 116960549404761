/* app-preloader */
.app-preloader .app-preloader-inner::after,
.app-preloader .app-preloader-inner::before {
 // @apply absolute h-full w-full rounded-full bg-slate-300/[.25] content-[''] dark:bg-navy-300/50;
 // tailwind classes: before:absolute before:h-full before:w-full before:rounded-full before:bg-slate-300/[.25] before:content-[''] before:dark:bg-navy-300/50
  // tailwind classes: after:absolute after:h-full after:w-full after:rounded-full after:bg-slate-300/[.25] after:content-[''] after:dark:bg-navy-300/50
  animation: spinner-grow 3s cubic-bezier(0.55, 0.15, 0.45, 0.85) infinite;
}

.app-preloader .app-preloader-inner::after {
  animation-delay: -1.5s;
}