.root {
}
body {
  -webkit-overflow-scrolling: touch;
}
.loading {
  color: #1ABC9C;
}
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

img,
svg {
  display: inline;
}

a,
button {
  cursor: pointer;
  text-decoration:inherit;
}

.pageContainer {
  opacity: 0;
  transition: 1s;
}

.fadeIn {
  opacity: 1;
}


.sticky {
  position: sticky;
  position: -webkit-sticky;
}
/**** SCROLL ********/
//.scroll {
//  scrollbar-color: var(--scroll-thumb-color, grey) var(--scroll-track, transparent);
//  scrollbar-width: thin;
//}
//.scroll::-webkit-scrollbar {
//  width: var(--scroll-size, 10px);
//  height: var(--scroll-size, 10px);
//
//}
//.scroll::-webkit-scrollbar-track {
//  background-color: var(--scroll-track, transparent);
//  border-radius: var(--scroll-track-radius, var(--scroll-radius));
//}
//.scroll::-webkit-scrollbar-thumb {
//  background-color: var(--scroll-thumb-color, grey);
//  background-image: var(--scroll-thumb, none);
//  border-radius: var(--scroll-thumb-radius, var(--scroll-radius));
//}
/*
.scroll-1 {
  --scroll-size: 10px;
  --scroll-radius: 10px;
  --scroll-track: rgb(0 0 0 / 40%);
  --scroll-thumb-color: #dfdfdf;
}
.scroll-2 {
  --scroll-size: 8px;
  --scroll-radius: 10px;
  --scroll-track: rgb(255 255 255 / 10%);
  --scroll-thumb-color: rgb(0 0 0 / 80%);
}*/
//.scroll-3 {
//  --scroll-size: 5px;
//  --scroll-radius: 10px;
//  --scroll-track: rgb(255 255 255 / 10%);
//  --scroll-thumb-color: #fff;
//  cursor:pointer;
//}
/*-
.scroll-4 {
  --scroll-size: 12px;
  --scroll-radius: 20px;
  --scroll-track: rgb(0 0 0 / 40%);
  --scroll-thumb: linear-gradient(45deg,
      rgba(255, 255, 255, 0.25) 20%, transparent 20%,
      transparent 40%, rgba(255, 255, 255, 0.25) 40%,
      rgba(255, 255, 255, 0.25) 60%, transparent 60%,
      transparent 80%, rgba(255, 255, 255, 0.25) 80%);
  --scroll-thumb-color: #f97316;
}
.scroll-5 {
  --scroll-size: 15px;
  --scroll-radius: 20px;
  --scroll-track: rgb(255 255 255 / 10%);
  --scroll-thumb: linear-gradient(45deg, #00aeff, #a68eff);
}
.scroll-6 {
  --scroll-size: 12px;
  --scroll-radius: 20px;
  --scroll-track: rgb(0 0 0 / 40%);
  --scroll-thumb: linear-gradient(
      rgba(255, 255, 255, 0.3) 20%, transparent 20%,
      transparent 40%, rgba(255, 255, 255, 0.3) 40%,
      rgba(255, 255, 255, 0.3) 60%, transparent 60%,
      transparent 80%, rgba(255, 255, 255, 0.3) 80%);
  --scroll-thumb-color: #10b981;
}*/

/*** DOCUMENTATION ******

.documentation-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 60%;
    margin: 0 auto;
    height: auto;
  }

  h1 {
    text-align: center;
    font-size: 2.5rem;

  }

  h2 {
    text-align: center;
    font-size: 2rem;

  }

  h3 {
    text-align: center;
    font-size: 1.5rem;

  }

  h4 {
    text-align: center;
    font-size: 1.25rem;

  }

  p {
    text-align: justify;
    text-align-last: center;
    font-size: 1rem;
    margin: 0 auto;
  }

  ul {
    font-size: 1rem;

    li {
      list-style: inside;
    }
  }

  ol {
    list-style: none;
    counter-reset: counter;
  }

  ol li {
    counter-increment: counter;
  }

  ol li::before {
    content: counter(counter) ". ";
  }


}
*/
.uploadLabel {
  //border: 1px dotted #ccc;
  color:#ccc;
  display: inline-block;
  padding: 7px 15px;
  cursor: pointer;
  border-radius: 5px;
  font-size:10px;
}

.uploadButton {
  display: none;
}
