:root {
    --shiki-color-text: #414141;
    --shiki-color-background: transparent;
    --shiki-token-constant: #1976d2;
    --shiki-token-string: #22863a;
    --shiki-token-comment: #aaa;
    --shiki-token-keyword: #d32f2f;
    --shiki-token-parameter: #ff9800;
    --shiki-token-function: #6f42c1;
    --shiki-token-string-expression: #22863a;
    --shiki-token-punctuation: #212121;
    --shiki-token-link: #22863a;
    --nextra-shiki-deleted: #f00;
    --nextra-shiki-inserted: #f00;
   }
   
   .dark {
    --shiki-color-text: #d1d1d1;
    --shiki-token-constant: #79b8ff;
    --shiki-token-string: #ffab70;
    --shiki-token-comment: #6b737c;
    --shiki-token-keyword: #f97583;
    --shiki-token-parameter: #ff9800;
    --shiki-token-function: #b392f0;
    --shiki-token-string-expression: #4bb74a;
    --shiki-token-punctuation: #bbbbbb;
    --shiki-token-link: #ffab70;
   }
   