.dashboard{
  &__grid{
    @media screen and (max-width: 768px){
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-gap: 1rem;
    }
    @media screen and (min-width: 768px){
      grid-template-columns: repeat(4, minmax(0, 1fr));
      
    }
    
    display: grid;
    
    grid-auto-columns: minmax(0,1fr);
    grid-auto-rows: 150px;
    grid-gap: 1rem;
    grid-auto-flow: dense;
    margin: 1rem 0;
  }
}