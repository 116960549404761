@tailwind base;
@tailwind components;

@import "./ui/__ui_colors";
@import "./ui/__ui_typography";
@import "./ui/__ui_loader";
@import "./ui/__ui_animation";
@import "./__theme_custom";
@import "./section/__section_header";
@import "./section/__section_help";
@import "./components/buttons/__button_feedback";
@import "./components/__table";
@import "./components/__tabs";
@import "./components/__dashboard";
@import "./components/__loader";
@import "./components/__carousel";



 

@tailwind utilities;
@layer base {
    html {
      @apply scroll-smooth;
    }
  }
