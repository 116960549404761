
/***** TABLES *****/
.table {
  width: 100%;
  margin: 0 auto;
  max-width: 960px;
  border-collapse: collapse;
}

.table th {
  padding: 15px;
}

.table td {
  cursor: pointer;
  //padding:10px;
  vertical-align: middle;
}

@media screen and (max-width: 820px) {
  table.responsive-table {
    border-collapse: collapse;
    border-style: hidden;
  }

  table.responsive-table {
    word-wrap: break-word;
  }

  table.responsive-table thead {
    position: absolute;
    left: -999em;
    top: -999em;
  }

  table.responsive-table td {
    display: block;
    float: left;
    /* ie9 and under hack */
    width: 100%;
    clear: both;
    padding: 10px 5px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  table.responsive-table tbody,
  table.responsive-table tr {
    display: block;
    
  }

  .responsive-table td:before {
    content: attr(data-th);
    display: block;
    font-weight: bold;
    margin: 0 0 2px;
    font-size: 0.8em;
    color: rgb(100, 116, 139);
  }

  .responsive-table tbody tr {
    float: left;
    width: 100%;
    //margin-bottom:10px;
    border: 1px dotted #F1F5F9;
    margin-top: 10px;
    padding: 10px;
    border-radius: 15px;
  }
}
