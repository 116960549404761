/**** TABS *******/

$max-tab-count: 5;
$tab-wrap-border-radius: 6px;

.tabLabel { 
  display: inline-block; 
}
/*
.tabLabel {
  display: inline-block;
  margin: 0 0 -1px;
  padding: 15px 25px;
  text-align: center;
  color: #bbb;
  border: 1px solid transparent;
}*/

.tab {
  display: none;

  @for $i from 1 through $max-tab-count {
    &:checked:nth-of-type(#{$i}) ~ .tab__Body:nth-of-type(#{$i}) {
      opacity: 1;
      transition: 0.5s opacity ease-in, 0.8s transform ease;
      position: relative;
      top: 0;
      z-index: 10;
      transform: translateY(0px);
      text-shadow: 0 0 0;
    }
  }

  &:first-of-type:not(:last-of-type) + .tabLabel {
    border-top-right-radius: $tab-wrap-border-radius;
    border-top-left-radius: $tab-wrap-border-radius;
  }

  &:last-of-type:not(:first-of-type) + .tabLabel {
    border-top-right-radius: $tab-wrap-border-radius;
    border-top-left-radius: $tab-wrap-border-radius;
    // border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  &:checked + .tabLabel {
    text-decoration:underline;
    color:rgb(79, 70, 229);
    font-weight:bold;
  }
 /* &:checked + .tabLabel {
    background-color: #fff;
    //background-color:#4B3FCF;
    color: #fff;
    box-shadow: 0 -1px 0 #fff inset;
    cursor: default;
    color: #333;
    border-top: 1px solid rgba(231, 236, 243, 1);
    border-left: 1px solid rgba(231, 236, 243, 1);
    border-right: 1px solid rgba(231, 236, 243, 1); // var(--neutral-100)
    &:hover {
      box-shadow: 0 -1px 0 #fff inset;
      background-color: #fff;
    }
  }*/

/*  + .tabLabel {
    box-shadow: 0 -1px 0 #eee inset;
    border-radius: $tab-wrap-border-radius $tab-wrap-border-radius 0 0;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    background-color: #f2f2f2;
    user-select: none;
    text-align: center;
    transition: 0.3s background-color ease, 0.3s box-shadow ease;
    height: 50px;
    //width: 150px;
    box-sizing: border-box;
    padding: 15px;

    &:hover {
      background-color: #f9f9f9;
      box-shadow: 0 1px 0 #f4f4f4 inset;
    }
  }*/

  &__Body {
    // padding: 10px 25px;
    // padding: 10px;
    min-height: 300px;
   // background-color: white;
    position: absolute;
    width: 100%;
    z-index: -1;
    opacity: 0;
    left: 0;
    top: 0;
    transform: translateY(-3px);
   // border-top-right-radius: $tab-wrap-border-radius;
    //border-bottom-left-radius: $tab-wrap-border-radius;
   // border-bottom-right-radius: $tab-wrap-border-radius;
   // box-shadow: 0 2px 1px 1px rgba(0, 0, 0, 0.12),1px 1px 1px rgba(0, 0, 0, 0.12);
  }
}
