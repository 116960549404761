
.feedback__button {
  // transition: all .3s cubic-bezier(.13,1.25,.95,1.61);

  animation: feedback_animation_out 0.3s cubic-bezier(0.13, 1.25, 0.95, 1.61)
    forwards;

  p {
    animation: feedback_animation_text_out 0.3s linear forwards;
    margin: 0 auto;
    display: none;
  }
  &:hover {
    animation: feedback_animation_in 0.3s cubic-bezier(0.13, 1.25, 0.95, 1.61)
      forwards;
    p {
      animation: feedback_animation_text_in 0.3s linear forwards;
      display: block;
    }
  }
}