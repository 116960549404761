@keyframes FadeZoomRight {
    from {
      transform: scale(1.05) translateY(20px);
      opacity: 0;
    }
    to {
      transform: scale(1) translateY(0px);
      opacity: 1;
    }
  }
  @keyframes FadeZoomLeft {
    from {
      transform: translateY(20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes swing {
    0%,
    30%,
    50%,
    70%,
    100% {
      transform: rotate(0deg);
    }
  
    10% {
      transform: rotate(10deg);
    }
  
    40% {
      transform: rotate(-10deg);
    }
  
    60% {
      transform: rotate(5deg);
    }
  
    80% {
      transform: rotate(-5deg);
    }
  }

  .preload-animation{
    animation-delay: 0s;
    animation-duration: 0s;
    *{
      animation-delay: 0s;
      animation-duration: 0s;
    }
    p{
      display: none;
    }
  }
  
  
  @keyframes feedback_animation_text_in {
    0%{}
    50%{
      display: block;
    }
    100%{
      opacity: 1;
      display: block;
    }
  
  
  }
  
  @keyframes feedback_animation_text_out {
    0% {
      opacity: 1;
      display: block;
    }
    50% {
      display: block;
      height: 0;
      width: 0;
    }
    100% {
      opacity: 0;
      height: 0;
      width: 0;
    }
  }
  
  @keyframes feedback_animation_in {
    0% {
      width: 3.5rem;
    }
    100% {
      width: 200px;
    }
  }
  
  @keyframes feedback_animation_out {
    100% {
      width: 3.5rem;
    }
    0% {
      width: 200px;
    }
  }